<template>
    <div class="next-page-navigator">
        <div class="link-wrapper left-wrapper">
            <div class="arrow-circle" v-if="isExistPrev"></div>
            <span class="label" v-if="isExistPrev" v-text=prevLabel></span>
            <a class="area-link" v-if="isExistPrev" v-bind:href=prevLink></a>
        </div>
        <div class="vert-line"></div>
        <div class="link-wrapper right-wrapper">
            <span class="label" v-if="isExistNext" v-text=nextLabel></span>
            <div class="arrow-circle" v-if="isExistNext"></div>
            <a class="area-link" v-if="isExistNext" v-bind:href=nextLink></a>
        </div>
    </div>
</template>

<script>
export default {
    name: "NextPageNavigator",
    props: {
        isExistPrev: {
            type: Boolean,
            required: true
        },
        isExistNext: {
            type: Boolean,
            required: true
        },
        prevLabel: {
            type: String,
            required: false
        },
        nextLabel: {
            type: String,
            required: false
        },
        prevLink: {
            type: String,
            required: false
        },
        nextLink: {
            type: String,
            required: false
        },
        color: {
            type: String,
            required: true
        }
    },
}
</script>

<style scoped>
.next-page-navigator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    position: relative;
}

.vert-line {
    width: 4px;
    height: 30px;
    background-color: v-bind(color);
}

.link-wrapper {
    display: flex;
    align-items: center;
    position: relative;

    /* 50vwにすると縦スクロールバーが出ている時に横スクロールバーが出現する */
    width: 45vw;
}

.left-wrapper {
    right: 5px;
    justify-content: flex-end;
}

.right-wrapper {
    left: 5px;
}

.label {
    font-size: 24px;
    margin: 0 30px;
}

.left-wrapper .arrow-circle {
    transform: rotate(180deg);
}

.arrow-circle {
    width: 30px;
    height: 30px;
    /* これが無いとテキストの量によって小さくなる */
    flex-shrink: 0;

    background-color: v-bind(color);

    /* Chrome用 */
    -webkit-mask-image: url("@/assets/img/icon/arrowCircleRight.svg");
    -webkit-mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;

    mask-image: url("@/assets/img/icon/arrowCircleRight.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
}

@media screen and (max-width: 1280px) {
    .vert-line {
        width: 2px;
        height: 18px;
    }

    .label {
        font-size: 11px;
        margin: 0 5px;
    }

    .arrow-circle {
        width: 18px;
        height: 18px;
    }
}
</style>