<template>
    <div class="heading3">
        <span class="text" v-text=text></span>
        <div class="line"></div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Heading3',
    props: {
        text: {
            type: String,
            required: true
        },
        fontColor: {
            type: String,
            default: "var(--gray5)"
        }
    },
}
</script>

<style scoped>
.heading3 {
    position: relative;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
}

.line {
    position: absolute;
    height: 20px;
    left: -21px;
    right: -21px;
    bottom: -3px;

    background-color: #EFEFEF;
    border-radius: 10px;
    z-index: 10;
}

.text {
    font-size: 36px;
    color: v-bind(fontColor);
    z-index: 20;
}

@media screen and (max-width: 1280px) {
    .line {
        height: 8px;
        left: -10px;
        right: -10px;
        bottom: 0;
    }

    .text {
        font-size: 16px;
    }
}
</style>