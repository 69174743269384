<template>
    <div class="view-all-link">
        <span class="label">View all</span>
        <div class="right-triangle"></div>
        <a class="area-link" :href=linkPath></a>
    </div>
</template>

<script>
export default {
    name: 'ViewAllLink',
    props: {
        linkPath: {
            type: String,
            required: true
        },
        color: {
            type: String,
            required: true
        },
    },
}
</script>

<style scoped>


.view-all-link {
    width: 200px;
    height: 52px;
    margin: 50px auto 0 auto;
    box-sizing: border-box;

    color: v-bind(color);
    border-color: v-bind(color);

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    background: #FFFFFF;
    border: 3px solid;
    border-radius: 31px;

    transition: .2s;
}

.label {
    font-size: 32px;
    line-height: 34px;
    margin-right: 9.25px;
    color: v-bind(color);

    transition: .2s;
}

.right-triangle {
    width: 21px;
    height: 21px;

    mask-image: url(@/assets/img/icon/rightTriangle.png);
    mask-size: cover;
    background-color: v-bind(color);

    transition: .2s;
}

.view-all-link:hover {
    background: v-bind(color);
}

.view-all-link:hover .label {
    color: #FFFFFF;
}

.view-all-link:hover .right-triangle {
    background-color: #FFFFFF;
}

@media screen and (max-width: 1280px) {
    .view-all-link {
        width: 80px;
        height: 20px;
        border-width: 1px;
        margin: 10px auto 0 auto;
    }

    .label {
        font-size: 13px;
    }

    .right-triangle {
        width: 9px;
        height: 9px;
    }
}
</style>