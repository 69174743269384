<template>
    <footer>
        <img
            class="scroll-to-top-btn"
            src="img/icon/scrollToTop.png"
            v-on:click="scrollToTop"
        >
        <SnsIconRow/>
        <div id="logo-row" class="hori-center-block">
            <img src="img/icon/logo.png">
            <span>Studio Shunart.</span>
        </div>
        <div id="copy-right" class="hori-center-block">
            © 2023 Studio Shunart.
        </div>
    </footer>
</template>

<script>
import SnsIconRow from './SnsIconRow.vue';

export default {
    name: "CommonFooter",
    components: { SnsIconRow, },
    data() {
        return {
            youTubeUrl: null,
            twitterUrl: null,
        };
    },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    },
}
</script>

<style scoped lang="scss">
footer {
    height: 350px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}

footer .scroll-to-top-btn {
    position: absolute;
    width: 55px;
    height: 85px;
    right: 204px;
    top: 55px;

    /* ただの画像なのでマウスオーバー時に押せる要素かわからないため */
    cursor: pointer;
}

#logo-row {
    position: absolute;
    height: 56px;
    top: 193px;

    display: flex;
    align-items: center;
}

#logo-row img {
    width: 80px;
    height: 56px;
}

#logo-row span {
    font-size: 32px;
    letter-spacing: 0.1em;
    color: var(--gray5);
}

#copy-right {
    position: absolute;
    height: 21px;
    top: 275px;

    font-size: 16px;
    color: #808080;
}

@media screen and (max-width: 1280px) {
    footer {
        height: 192px;
    }

    .scroll-to-top-btn {
        display: none;
    }

    #logo-row {
        height: 30px;
        top: 85px;

        img {
            width: 48px;
            height: 30px;
        }

        span {
            font-size: 16px;
        }
    }

    #copy-right {
        top: 150px;
    }
}
</style>
