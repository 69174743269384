<template>
    <div></div>
</template>

<script>
export default {
    name: "ColoredIcon",
    props: {
        src: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
    },
    computed: {
        maskImage() {
            return `url(${this.src})`;
        },
    },
}
</script>

<style scoped>
div {
    mask-image: v-bind(maskImage);
    mask-position: center;
    mask-size: contain;
    background-color: v-bind(color);
    mask-repeat: no-repeat;
}
</style>
