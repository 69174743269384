<template>
    <li class="article-card card">
        <img :src="eyeCatchImgPath" class="eye-catch">

        <div class="title-desc">
            <h4 class="title" v-text=titleText></h4>
            <div class="title-line"></div>
            <p class="desc" v-html=descText></p>
        </div>
        <a :href=href class="area-link"></a>
    </li>
</template>

<script>
export default {
    name: "ArticleCard",
    props: {
        article: Object
    },
    data () {
        return {
            eyeCatchImgPath: "",
            titleText: "",
            descText: "",
            href: "",
        }
    },
    created() {
        this.eyeCatchImgPath = this.article.eyecatch.url;
        this.titleText = this.article.title;
        // タグを消す。<br>か<h>か<p>で改行する。
        // 内容を未作成のときはcontentが無い
        this.descText = (this.article.content || "")
            .replace(/(<br>|<\/h\d>|<\/p>)/g, '\n')
            .replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '')
            .replace(/\n/g, "<br>");
        this.href = `article.html?id=${this.article.id}`
    }
}
</script>

<style scoped>
.article-card {
    width: 1351px;
    height: 280px;

    display: flex;
    gap: 50px;
    padding: 33px;
    box-sizing: border-box;

    position: relative;
}

.eye-catch {
    width: 275px;
    height: 200px;
    flex: none;
    border-radius: 13px;
    object-fit: cover;
    background-color: #d9d9d9;
}

.title-desc {
    width: 940px;
    position: relative;
}

.title {
    width: 100%;
    position: relative;

    font-size: 36px;
    color: var(--gray6);
    line-height: 50px;
    font-weight: 400;

    /* はみ出した文字は...にする */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.title-line {
    position: absolute;
    left: -25px;
    right: 0;
    border-bottom: 4px solid #7E7E7E;;
}

.desc {
    margin-top: 20px;
    width: 100%;
    font-size: 24px;
    color: var(--gray6);

    /* はみ出した文字は...にする の複数行バージョン */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4; /* 表示行数 */
    overflow: hidden;
}

@media screen and (max-width: 1280px) {
    .article-card {
        width: 100%;
        height: 110px;
        padding: 10px;
        column-gap: 15px;
    }

    .eye-catch {
        width: 90px;
        height: 55px;
    }

    .title-desc {
        width: calc(100% - 115px);
    }

    .title {
        font-size: 14px;
        line-height: 20px;
    }

    .title-line {
        border-width: 1px;
        left: -7px;
    }

    .desc {
        margin-top: 6px;
        font-size: 11px;
    }
}
</style>
