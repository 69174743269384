export const colors = {
    news: "#F9567D",
    works: "#00538F",
    info: "#F9AE56",
    about: "#80D9FF",
    contact: "#C9EC9C",
    standard: "#FFEBCC",
    premium: "#FFA030",
    luxury: "#F66646",

    gray7: "#7E7E7E",
}