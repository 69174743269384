<template>
    <div class="icon-text-card card">
        <img :src="iconPath">
        <span v-text="text"></span>
        <a class="area-link" :href="href"></a>
    </div>
</template>

<script>
export default {
    name: "IconTextColCard",
    props: {
        iconPath: String,
        text: String,
        href: String,
    }
}
</script>

<style scoped>
.icon-text-card {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    padding: 25px;
    /* paddingによって大きさが変わることを防止するため */
    box-sizing: border-box;
    position: relative;
}

.icon-text-card img {
    width: 200px;
    height: 200px;
    object-fit: contain;
}

.icon-text-card span {
    font-size: 32px;
    color: var(--gray7);
    text-align: center;
}

.icon-text-card:hover {
    border: solid 3px var(--gray7);
    box-shadow: none;
}

@media screen and (max-width: 1280px) {
    .icon-text-card {
        padding: 15px;
    }

    .icon-text-card img {
        width: 60px;
        height: 60px;
    }

    .icon-text-card span {
        font-size: 14px;
    }
}
</style>