<template>
    <div class="price-row">
        <div class="unit-col">
            <div class="about-tax">税抜</div>
            <div class="unit">¥</div>
        </div>
        <span class="price">{{ price }}</span>
    </div>
</template>

<script>
export default {
    "name": "PriceRow",
    props: {
        price: String,
        color: String,
        priceFontSize: String,
    },
    computed: {
        unitColWidthPx() { return this.priceFontSize * 0.5 + "px"},
        aboutTaxFontSizePx() { return this.priceFontSize * 0.2 + "px"},
        unitFontSizePx() { return this.priceFontSize * 0.5 + "px"},
        priceFontSizePx() { return this.priceFontSize + "px"},
    }
}
</script>

<style lang="scss">
.price-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: v-bind(priceFontSize);

    * {
        font-weight: bold;
        color: v-bind(color);
    }

    .unit-col {
        display: flex;
        justify-content: end;
        align-items: center;
        flex-direction: column;
        width: v-bind(unitColWidthPx);

        .about-tax {
            font-size: v-bind(aboutTaxFontSizePx);
            height: v-bind(aboutTaxFontSizePx);
        }

        .unit {
            font-size: v-bind(unitFontSizePx);
            height: v-bind(unitFontSizePx);
        }
    }

    .price {
        font-size: v-bind(priceFontSizePx);
        white-space: nowrap;
    }
}

@media screen and (max-width: 1280px) {
    .unit-col .about-tax {
        white-space: nowrap;
        transform: scale(0.7);
    }
}
</style>