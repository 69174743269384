<template>
    <div class="model-card">
        <img class="bgImg" :src=bgPanelImgPath>
        <a class="area-link" :href="`worksDetail.html?id=${id}`">
            <img class="portrateImg" :src=portrateImgPath ontouchstart="" :class="{'is-big' : isBig}">
        </a>
    </div>
</template>

<script>
export default {
    name: "ModelCard",
    props: {
        id: String,
        bgPanelImgPath: String,
        portrateImgPath: String,
        isBig: Boolean,
    },
}
</script>

<style scoped>
.model-card {
    width: 308px;
    height: 550px;
    border-radius: 16px;

    position: relative;
}

.bgImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.portrateImg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    width: 100%;
    height: 100%;
    object-fit: cover;

    transition: .5s;

}

.portrateImg.is-big {
    transform: translateX(-50%) scale(1.15);
}

.portrateImg:hover {
    transform: translateX(-50%) scale(1.25);
    filter: drop-shadow(0 0 7px rgba(0, 0, 0, 0.7));
}

@media screen and (max-width: 1280px) {
    .model-card {
        width: 168px;
        height: 300px;
    }
}
</style>