export const infoPageList = [
    {
        iconImgPath: "img/icon/task.png",
        path: "requestProcess.html",
        title: "ご依頼の流れについて",
        desc: "Vtuberモデルの制作を依頼したいけど、どうすれば良いのか分からない……<br>そういった方へ向けて、お問い合わせから納品までの流れをご確認いただけます。",
    },
    {
        iconImgPath: "img/icon/coin.png",
        path: "pricePlan.html",
        title: "料金について",
        desc: "依頼したいけど、どのくらいのお金が必要なの？？<br>お見積りの前に目安となる基本料金や、プランについてご紹介いたします。",
    },
    {
        iconImgPath: "img/icon/noteFavorite.png",
        path: "option.html",
        title: "オプションについて",
        desc: "表情やポーズ差分、よりクオリティーの高い動きを実装する<br>オプションをご紹介いたします。"
    },
    {
        iconImgPath: "img/icon/boxTick.png",
        path: "deadline.html",
        title: "納期について",
        desc: "依頼してからどのくらいで完成するの？？<br>ご希望するプランによって変わりますが、おおよその納期についてご説明いたします。"
    },
    {
        iconImgPath: "img/icon/information.png",
        path: "note.html",
        title: "注意事項！",
        desc: "お問い合わせの前に必ずご一読ください。<br>トラブルを避けるため、ご依頼の前に必ずご確認して頂きたいことがございます。"
    },
    {
        iconImgPath: "img/icon/messageQuestion.png",
        path: "qAndA.html",
        title: "Q&A よくあるご質問",
        desc: "依頼したいけど分からないことが沢山……<br>よく寄せられるご質問をQ&A形式で回答しています。"
    },
]