<template>
    <div class="hover-link">
        <span class="label label-main">{{ labelMain }}</span>
        <span class="label label-sub-">{{ labelSub }}</span>
        <a class="area-link" :href=href></a>
    </div>
</template>

<script>
export default {
    name: "HoverLink",
    props: {
        labelMain: String,
        labelSub: String,
        href: String,
        labelColor: String,
        boarderColor: String,
    }
}
</script>

<style scoped>
.hover-link {
    background: white;
    border: 5px solid v-bind(boarderColor);
    box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    box-sizing: border-box;

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition: .2s;
}

.label {
    color: v-bind(labelColor);
    font-weight: 600;
    transition: .2s;
}

.label-main {
    font-size: 32px;
}

.label-sub {
    font-size: 15px;
}

.hover-link:hover {
    background: v-bind(boarderColor);
}

.hover-link:hover .label {
    color: white;
}

.hover-link:active {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 1280px) {
    .hover-link {
        border-width: 3.5px;
        box-shadow: 1px 2px 7.5px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
    }

    .label-main {
        font-size: 16px;
    }

    .label-sub {
        font-size: 8px;
    }
}
</style>