<template lang="">
    <NextPageNavigator
        :isExistPrev=isExistPrev
        :isExistNext=isExistNext
        :prevLabel=prevLabel
        :nextLabel=nextLabel
        :prevLink=prevLink
        :nextLink=nextLink
        :color=colors.info
    />
</template>

<script>
import { colors } from "@/consts/colors";
import { infoPageList } from "@/consts/infoPageList";
import NextPageNavigator from "@/components/NextPageNavigator.vue";

export default {
    name: "InfoPageNavigator",
    components: {
        NextPageNavigator,
    },
    data() {
        return {
            colors,
            isExistPrev: false,
            isExistNext: false,
            prevLabel: '',
            nextLabel: '',
            prevLint: '',
            nextLink: '',
        };
    },
    created() {
        const curPageIdx = infoPageList.findIndex(page => page.path != "" && location.href.includes(page.path));
        if (curPageIdx > 0) {
            this.isExistPrev = true;
            const prevPage = infoPageList[curPageIdx - 1];
            this.prevLabel = prevPage.title;
            this.prevLink = prevPage.path;
        }
        if (curPageIdx < infoPageList.length - 1) {
            this.isExistNext = true;
            const nextPage = infoPageList[curPageIdx + 1];
            this.nextLabel = nextPage.title;
            this.nextLink = nextPage.path;
        }
    }
}
</script>

<style>
</style>