<template>
    <div class="text-link-btn">
        <span class="label" v-text=label></span>
        <a class="area-link" v-bind:href=link></a>
    </div>
</template>

<script>
export default {
    name: "TextLinkBtn",
    props: {
        label: {
            type: String,
            required: true
        },
        link: {
            type: String,
            required: true
        },
        color: {
            type: String,
            required: true
        }
    },
}
</script>

<style scoped>
.text-link-btn {
    padding: 10px 35px;
    background: white;
    border: 4px solid v-bind(color);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    box-sizing: border-box;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

    transition: .2s;
}

.label {
    font-size: 32px;
    line-height: 52px;
    color: v-bind(color);

    transition: .2s;
}
.text-link-btn:hover {
    background-color: v-bind(color);
}

.text-link-btn:hover .label {
    color: white;
}

@media screen and (max-width: 1280px) {
    .text-link-btn {
        border-width: 2px;
        padding: 7px 22px;
        border-radius: 10px;
    }

    .label {
        font-size: 16px;
        line-height: 20px;
    }
}
</style>