<template>
    <div class="req-link">
        <span class="label">ご依頼はこちら</span>
        <div class="icon"></div>
        <a class="area-link" href="contact.html"></a>
    </div>
</template>

<script>
export default {
    name: "ReqLink"
}
</script>

<style scoped lang="scss">
.req-link {
    width: 420px;
    height: 110px;

    background: white;
    border: 5px solid var(--info-color);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    box-sizing: border-box;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    transition: .2s;

    .label {
        margin-right: 24px;
        font-size: 38px;
        line-height: 52px;
        color: var(--info-color);

        transition: .2s;
    }

    .icon {
        width: 46px;
        height: 46px;

        mask-image: url(@/assets/img/icon/checkBubble.png);
        mask-size: contain;
        background-color: var(--info-color);

        transition: .2s;
    }

    &:hover {
        background: var(--info-color);

        .label {
            color: white;
        }

        .icon {
            background-color: white;
        }
    }

    &:active {
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
    }
}

// 1280px以上の画面幅の場合


@media screen and (max-width: 1280px) {
    .req-link {
        width: 200px;
        height: 50px;
        border-width: 3px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 11px;

        .label {
            margin-right: 10px;
            font-size: 18px;
            line-height: 24px;
        }

        .icon {
            width: 20px;
            height: 20px;
        }
    }
}
</style>