<template lang="">
    <div class="sns-icon-row">
            <a :href="youTubeUrl" target="_blank">
                <img class="icon" src="img/icon/youtube.png">
            </a>

            <a :href="twitterUrl" target="_blank">
                <img class="icon" src="img/icon/twitter.png">
            </a>
        </div>
</template>

<script>
export default {
    name: 'SnsIconRow',
    data() {
        return {
            youTubeUrl: null,
            twitterUrl: null,
        };
    },
    async created() {
        const contactAddressUrl = "editable/contactAddress.json";
        const address = await (await fetch(contactAddressUrl)).json();
        this.youTubeUrl = address.youTubeUrl;
        this.twitterUrl = address.twitterUrl;
    },
}
</script>

<style scoped>
.sns-icon-row {
    display: flex;
    align-items: center;
    gap: 70px;
}

.icon {
    width: 50px;
    height: 50px;
}


@media screen and (max-width: 1280px) {
    .sns-icon-row {
        gap: 25px;
    }

    .icon {
        width:  25px;
        height: 25px;
    }
}
</style>