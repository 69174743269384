<template>
    <div :id=id class="heading1">
        <span class="main" v-text=mainText></span>
        <span class="sub" v-html=subText></span>
        <div class="line"></div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Heading1',
    props: {
        id: {
            type: String,
            required: true
        },
        mainText: {
            type: String,
            required: true
        },
        subText: {
            type: String,
            required: true
        },
        mainTextColor: {
            type: String,
            required: true
        },
    },
    mounted() {
        this.judgeAnime();
        window.addEventListener('scroll', this.judgeAnime);
    },
    methods: {
        judgeAnime() {
            const wrapper = document.querySelector(`#${this.id}.heading1`);
            const rect = wrapper.getBoundingClientRect();
            const scroll = window.pageYOffset || document.documentElement.scrollTop;
            const offset = rect.top + scroll;
            const windowHeight = window.innerHeight;
            const hasAnimated = wrapper.classList.contains("is-animated");
            if (!hasAnimated && offset < scroll + windowHeight) {
                this.playSlideInAnime();
            }
        },
        playSlideInAnime() {
            const main = document.querySelector(`#${this.id} .main`);
            const sub = document.querySelector(`#${this.id} .sub`);
            main.classList.add("is-animated");
            sub.classList.add("is-animated");
        }
    }
}
</script>

<style scoped>
.heading1 {
    position: relative;
    text-align: center;
    display: inline-flex;
    flex-direction: column;

    transition: .5s;
}

.line {
    position: absolute;
    height: 42px;
    top: 73px;
    left: -110px;
    right: -110px;

    background-color: #EFEFEF;
    border-radius: 21px;
    z-index: 10;
}

.main {
    font-size: 75px;
    line-height: 105px;
    color: v-bind(mainTextColor);
    z-index: 20;

    position: relative;
    left: -200%;
    transition: .5s;
}

.sub {
    font-size: 16px;
    color: var(--gray7);
    z-index: 20;

    position: relative;
    right: -200%;
    transition: .5s;
}

.main.is-animated {
    left: 0;
}

.sub.is-animated {
    right: 0;
}

@media screen and (max-width: 1280px) {
    .line {
        height: 17px;
        top: 25px;
        left: -25px;
        right: -25px;
    }

    .main {
        font-size: 30px;
        line-height: 35px;
    }

    .sub {
        font-size: 7px;
    }
}
</style>