<template>
    <header>
        <div id="logo-area">
            <img src="img/icon/logo.png" alt="">
            <span>Studio Shunart.</span>
            <a href="index.html" class="area-link"></a>
        </div>

        <div id="hamburger">
            <nav class="navMenu">
                <ul class="gNav-menu">
                    <li><a href="index.html">Home</a></li>
                    <li><a id="news-link" href="news.html">News</a></li>
                    <li><a id="works-link" href="works.html">Works</a></li>
                    <li><a id="info-link" href="info.html">Info</a></li>
                    <li><a id="about-link" href="about.html">About</a></li>
                    <li><a id="contact-link" href="contact.html">Contact</a></li>
                </ul>
                <SnsIconRow v-if="useHamburger" />
            </nav>
            <div class="btn-gNav">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </header>
</template>

<script>
import SnsIconRow from './SnsIconRow.vue';

export default {
    name: "CommonHeader",
    methods: {
        setupHamburger() {
            const btnGNav = document.querySelector(".btn-gNav");
            const navMenu = document.querySelector(".navMenu");
            // ハンバーガーメニューをクリックされたらナビゲーションメニューを開く
            btnGNav.addEventListener("click", () => {
                btnGNav.classList.toggle("open");
                navMenu.classList.toggle("open");
            });
            // ハンバーガーメニュー内の要素をクリックされたらナビゲーションメニューを閉じる
            const gNavItems = document.querySelectorAll(".gNav-menu li a");
            gNavItems.forEach((item) => {
                item.addEventListener("click", () => {
                    btnGNav.classList.remove("open");
                    navMenu.classList.remove("open");
                });
            });
        }
    },
    mounted() {
        this.setupHamburger();
    },
    computed: {
        useHamburger: function () {
            return window.innerWidth <= 1280;
        }
    },
    components: { SnsIconRow }
}
</script>

<style scoped lang="scss">
header {
    position: fixed;
    width: 100%;
    height: 113px;
    left: 0px;
    top: 0px;
    /* area-linkよりも手前 */
    z-index: 1000;
}

#logo-area {
    position: absolute;
    width: 474px;
    height: 100%;
    left: 0;
    top: 0;

    &::before {
        position: absolute;
        width: 100%;
        height: 100%;
        left: -57px;
        background-color: white;

        content: "";
        transform: skewX(-45deg);
        border-bottom-right-radius: 36px;
    }

    img {
        position: absolute;
        width: 140px;
        height: 97px;
        left: -6px;
        top: 16px;
    }

    span {
        position: absolute;
        left: 112px;
        top: 39px;

        font-size: 32px;
        letter-spacing: 0.1em;
        color: var(--gray5);
    }
}

// ロゴを小さくする
@media screen and (max-width: 1280px) {
    header {
        height: 45px;
    }

    #logo-area {
        width: 160px;

        &::before {
            width: 170px;
            left: -22px;
            background-color: white;

            content: "";
            transform: skewX(-45deg);
            border-bottom-right-radius: 6px;
        }

        img {
            width: 46px;
            height: 32px;
            top: 10px;
        }

        span {
            font-size: 10px;
            left: 43px;
            top: 17px;
        }
    }
}

nav {
    position: absolute;
    height: 53px;
    right: 0px;
    top: 36px;
    background-color: white;
    border-radius: 28px 0 0 28px;

    ul {
        display: flex;
        flex-direction: row;
        margin: 10px;

        li {
            margin: 0 20px;
            font-size: 24px;
            line-height: 32px;
            list-style-type: none;

            a {
                color: var(--gray7);
            }
        }
    }
}

/* ハンバーガーメニュー用↓ */
.btn-gNav{
    display: none;
}

@media screen and (max-width: 1280px) {
    #hamburger {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 36px;
        height: 36px;
        background-color: #f7f7f7;
        border-radius: 50%;

        .btn-gNav{
            position: relative;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            cursor: pointer;
            z-index: 3;
            transition: .4s;

            display: block;

            span {
                position: absolute;
                width: 18px;
                height: 2px;
                background: var(--gray7);
                border-radius: 2px;
                transition: .4s;
                left: 9px;

                &:nth-child(1){
                    top: 36%;
                }
                &:nth-child(2){
                    top: 50%;
                }
                &:nth-child(3){
                    top: 64%;
                }
            }

            &.open {
                span {
                    top: 50%;

                    &:nth-child(1){
                        -webkit-transform: rotate(-45deg);
                        -moz-transform   : rotate(-45deg);
                        transform        : rotate(-45deg);
                    }

                    &:nth-child(2), &:nth-child(3){
                        -webkit-transform: rotate(45deg);
                        -moz-transform   : rotate(45deg);
                        transform        : rotate(45deg);
                    }
                }
            }
        }

        .navMenu {
            position: fixed;
            top: 0;
            right: -100%;
            width: 100%;
            height: 100%;
            font-size: 16px;
            box-sizing: border-box;
            z-index: 2;
            padding-top: 50px;
            transition: .3s;

            &.open{
                right: 0px;
                background-color: white;
                opacity: 0.95;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .gNav-menu{
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: 30px;
                margin-bottom: 90px;
            }

            .gNav-menu li{
                font-size: 30px;
                margin: 0;
            }
        }
    }

    // リンク毎に色を設定する
    #about-link { color: var(--about-color); }
    #works-link { color: var(--works-color); }
    #news-link { color: var(--news-color); }
    #info-link { color: var(--info-color); }
    #contact-link { color: var(--contact-color); }
}
/* ハンバーガーメニュー用↑ */
</style>
