export async function mailLinkClickHandler() {
    const contactAddressUrl = "editable/contactAddress.json";
    const address = await (await fetch(contactAddressUrl)).json();
    const mailAddress = address.mailAddress;

    // 規定のメーラーを使っていない人向けにメアドをコピーする
    const copyFrom = document.createElement("textarea");
    copyFrom.textContent = mailAddress;
    const bodyElm = document.getElementsByTagName("body")[0];
    bodyElm.appendChild(copyFrom);
    copyFrom.select();
    document.execCommand("copy");
    copyFrom.remove();
    alert(`メールアドレスをコピーしました。\n${mailAddress}`);

    // 規定のメーラーを起動する
    const href = "mailto:" + mailAddress + "?subject=HPからのお問い合わせ";
    const a = document.createElement("a");
    a.href = href;
    a.click();
    a.remove();
}